import { Layout, Menu, Popconfirm } from 'antd'
import {
  HomeOutlined,
  DiffOutlined,
  EditOutlined,
  LogoutOutlined
} from '@ant-design/icons'
import './index.scss'
import { Outlet,Link, useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { useStore } from "../../store";
import { useEffect } from 'react';


const { Header, Sider } = Layout

const GeekLayout = () => {
  const {pathname}=useLocation();
  const{userStore,loginStore}=useStore();
  const nvaigate=useNavigate();

  useEffect(()=>{
     userStore.GetUserInfo();
  },[userStore])

  const onConfirm=()=>{
   loginStore.clearToken();
   nvaigate('/login');

  }
  /*

            <Menu.Item icon={<DiffOutlined />} key="/ocpp_location">
            <Link to={'/ocpp_location'}>ocpp location</Link>
            </Menu.Item>


            <Menu.Item icon={<DiffOutlined />} key="/meter_value">
            <Link to={'/meter_value'}>Meter Value</Link>
            </Menu.Item>

            <Menu.Item icon={<DiffOutlined />} key="/cdr">
            <Link to={'/cdr'}>Cdr</Link>
            </Menu.Item>

            <Menu.Item icon={<DiffOutlined />} key="/session">
            <Link to={'/session'}>Session</Link>
            </Menu.Item>

            
            <Menu.Item icon={<DiffOutlined />} key="/authorize">
            <Link to={'/authorize'}>Authorize</Link>
            </Menu.Item>

            <Menu.Item icon={<DiffOutlined />} key="/status_ocpp">
            <Link to={'/status_ocpp'}>Status</Link>
            </Menu.Item>
                       <Menu.Item icon={<DiffOutlined />} key="/report">
            <Link to={'/report'}>Report</Link>
            </Menu.Item>

  */

  return (
    <Layout>
      <Header className="header">
        <div className="logo" />
        <div className="user-info">
          <span className="user-name">{userStore.userinfo.name}</span>
          <span className="user-logout">
            <Popconfirm onConfirm={onConfirm} title="do you want to exit？" okText="confirm" cancelText="cancel">
              <LogoutOutlined /> Déconnexion
            </Popconfirm>
          </span>
        </div>
      </Header>
      <Layout>
        <Sider width={200} className="site-layout-background">
          <Menu
            mode="inline"
            theme="dark"
            defaultSelectedKeys={[pathname]}
            style={{ height: '100%', borderRight: 0 }}
          >
            <Menu.Item icon={<HomeOutlined />} key="/">
              <Link to={'/alert_resa'}>Accueil</Link>
            </Menu.Item>


            <Menu.Item icon={<DiffOutlined />} key="/alert_resa">
            <Link to={'/alert_resa'}>AlertResa</Link>
            </Menu.Item>

          </Menu>
        </Sider>
        <Layout className="layout-content" style={{ padding: 20 }}>
        <Outlet></Outlet>

        </Layout>
      </Layout>
    </Layout>
  )
}

export default observer(GeekLayout)