import axios from 'axios'
import { getToken } from './token';

const http = axios.create({
    baseURL: 'https://oauth.clem.mobi/oauth/token',
    timeout: 15000
})
// 添加请求拦截器
http.interceptors.request.use((config) => {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    const token = getToken();

    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }


    return config
}, (error) => {
    return Promise.reject(error)
})

// 添加响应拦截器
http.interceptors.response.use((response) => {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response
}, (error) => {
   if(error.response.status===401)
   {
    window.location.href = '/login';
   }
    return Promise.reject(error)
})

export { http }