import React, { useState, useEffect } from 'react';
import { Descriptions, Card, Form, Select, Button, DatePicker, Space, Table,Row,Col } from 'antd';
import './index.scss';
import { http } from '../../utils'
import { useSearchParams } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';


const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const Cdr = () => {

    const [params] = useSearchParams();
    const id = params.get('id');


    const [stationlist, setList] = useState({
        list: [],
        count: 0
    });
    const [data, setData] = useState({
        data:[],
        totalVolume:{
            "total_energy": 0,
            "start": "",
            "end": ""
        }
    
    }    );


    const onFinish = async (values) => {

        const newParams = {};
        newParams.station_id = values.location_id;
        let url = `http://172.16.3.3/api-ocpp-admin/public/index.php/api/ocpp/cdrs?location_id=${newParams.station_id}`;
        if (values.date) {
            newParams.startdate = values.date[0].format('YYYY-MM-DD')
            newParams.enddate = values.date[1].format('YYYY-MM-DD')
            url += `&startdate=${newParams.startdate}&enddate=${newParams.enddate}&limit=-1`;
        }
        const stationdetail = await http.get(url);
        setData(stationdetail.data);

    };

    useEffect(
        () => {

            const GetStations = async () => {
                const res = await http.get('http://172.16.3.3/api-ocpp-admin/public/index.php/api/ocpp/status');
                const { data } = res.data;
                setList({
                    list: data,
                    count: data.length
                })

            }
            GetStations();

        }, [id]
    )

    const cdr_columns = [

        {
            title: 'Authenticate',
            dataIndex: 'media_id',
            key: 'media_id'
        },
        {
            title: 'start',
            dataIndex: 'start',
            key: 'start'
        },
        {
            title: 'end',
            dataIndex: 'end',
            key: 'end'
        },
        {
            title: 'total_volume',
            dataIndex: 'total_volume',
            key: 'total_volume'
        },


    ];

    const filterOption = (input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0


    return (
        <div>
            <Row    gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}>

                <Col span={12}>   <Card>
                    <Descriptions title="Meter Value">

                        <Space direction="vertical" size={12}>
                            <Form
                                style={{ maxWidth: 600 }}
                                onFinish={onFinish}
                                initialValues={{ status: -1 }}>




                                <Form.Item label="location name" name="location_id">
                                    <Select
                                        placeholder="location_name"
                                        defaultValue=""
                                        style={{ width: 300 }}
                                        showSearch
                                        filterOption={filterOption}
                                    >
                                        {stationlist.list.map((ss) => (
                                            <Select.Option key={ss.location_id} value={ss.location_id}>
                                                {ss.location_name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item label="date" name="date">
                                    <RangePicker format={dateFormat}></RangePicker>
                                </Form.Item>


                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{ marginLeft: 80 }}>
                                        submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Space>




                    </Descriptions>
                </Card> </Col>


                <Col span={12}>   <Card title="Total energy" bordered={false} style={{ width: 400 }}>
                     <p><b>{data.totalVolume.start}</b> -- <b>{data.totalVolume.end}</b></p>
                    <p><b className='blue-text'>{data.totalVolume.total_energy}</b></p>

                </Card> </Col>

            </Row>




            <Card title={`Cdr ：`}>
                <Table
                    rowKey="id"
                    dataSource={data.data}
                    columns={cdr_columns}
                />


            </Card>

        </div>

    );

}


export default Cdr;