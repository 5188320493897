import { Link, useNavigate } from 'react-router-dom'
import { Badge,Card, Breadcrumb, Form, Button, Radio, DatePicker, Select, Table, Input, Space } from 'antd'
import './index.scss'
import { useState, useEffect } from 'react'
import { http } from '../../utils'
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select
const { RangePicker } = DatePicker

const OcppLocation = () => {

    const [stationlist, setList] = useState({
        list: [],
        count: 0
    });
    const [params, setParams] = useState(
    )

    useEffect(
        () => {
            const LoadList = async () => {
      
                const res = await http.get('https://api-supervision.clem.mobi/api/ocpp/status', { params });
                const { data } = res.data;
                setList({
                    list: data,
                    count: data.length
                })

            }
            LoadList();
        }, [params]
    )

    const onFinish = (values) => {

        const { status, name,type } = values;
        const newParams = {};
        newParams.status = status;
        newParams.type = type;
        if (name)
            newParams.name = name;
        setParams({ ...params, ...newParams });
    };

    const navigate = useNavigate();


    const goStationDetails = (data) => {

        navigate(`/article?id=${data.location_id}`, { shouldRefresh: true });
    }



    const columns = [

        {
            title: 'name',
            dataIndex: 'location_name',
            key: 'name'

        },
        {
            title: 'id_ocpp',
            dataIndex: 'id_ocpp',
            key: 'id_ocpp'
        },
        {
            title: 'Status',
            dataIndex: 'isOnline',
            key: 'isOnline',
            render: (isOnline) => (isOnline ? 'Online' : 'Offline'),
        },
        {
            title: 'last session',
            dataIndex: 'session',
            key: 'session'
        },
        {
            title: 'start',
            dataIndex: 'session_start',
            key: 'session_start'
        },
        {
            title: 'stop',
            dataIndex: 'session_stop',
            key: 'session_stop'
        },
        {
            title: 'operate',
            render: data => (
                <Space size="middle">
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<SearchOutlined />}
                        onClick={() => goStationDetails(data)}
                    />
                </Space>
            )
        }

    ];


    return (
        <div>
            <Card
                title={
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item>
                            <Link to="/home">home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>stations</Breadcrumb.Item>
                    </Breadcrumb>
                }
                style={{ marginBottom: 20 }}
            >
                <Form
                    style={{ maxWidth: 600 }}
                    onFinish={onFinish}
                    initialValues={{ status: -1 }}>
                    <Form.Item label="status" name="status">
                        <Radio.Group>
                            <Radio value={-1}>all</Radio>
                            <Radio value={1}>online</Radio>
                            <Radio value={0}>offline</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="name" name="name">
                        <Input placeholder="input placeholder" />
                    </Form.Item>


                    <Form.Item label="type" name="type">
                    <Select
                    placeholder="type"
                    defaultValue="false"
                    style={{ width: 120 }}
                        >
                        <Option value="false">All</Option>
                            <Option value="ocpp16">Ocpp16</Option>
                            <Option value="mopboxv5">Mopboxv5</Option>
                            <Option value="spoony">Spoony</Option>
                            <Option value="ccds">CCDS</Option>
                        </Select>
                    </Form.Item>

           

                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ marginLeft: 80 }}>
                            submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Card title={`station list：`}>
                <Table
                    rowKey="id"
                    dataSource={stationlist.list}
                    columns={columns}
                    pagination={{

                        pageSize: 100,
                        showSizeChanger: false

                    }}
                />
            </Card>


        </div>
    )
}

export default OcppLocation;