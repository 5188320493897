
import { makeAutoObservable } from "mobx";
import { http,setToken,getToken, removeToken } from "../utils";

class LoginStore{

    token=getToken()||"";
    constructor(){
        makeAutoObservable(this);
    }
    getToken=async (username,password)=>
    {
        
       const res= await http.post('https://oauth2.clem.mobi/oauth/v1/token',{
        'grant_type': 'password',
        'username': username,
        'password': password,
        'client_id': 'clem.mobi-connected-91r$oxwytlDScoBLOKG7mj$IhAKriMsSUUz$amsw',
        'client_secret': '6tO9!pHqZ9vtD7So2ElcPHwr0t1I!AVAO4WWf3D!bBVh@$$tke' 
      })
        this.token=res.data.access_token;
        setToken(this.token);
    }
    clearToken=()=>{
        this.token="";
        removeToken();
    }

}

export default LoginStore