import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './pages/Layout';
import Login from './pages/Login';
import { Button } from 'antd';
import { AuthComponent } from './components/AuthComponent';
import './App.css';

import Home from './pages/Home';
import Article from './pages/Article';
import OcppLocation from './pages/OcppLocation';
import MeterValue from './pages/MeterValue';
import Cdr from './pages/Cdr';
import Session from './pages/Session';
import Authorize from './pages/Authorize';
import StatusOcpp from './pages/StatusOcpp';
import AlertResa from './pages/AlertResa';
import Report from './pages/Report';
import ReportResult from './pages/ReportResult';

function App() {
  return (

    <BrowserRouter>

      <div className="App">
        <Routes>
          <Route path='/' element={
            <AuthComponent><Layout /></AuthComponent>
          }>


            <Route index element={<AlertResa />}></Route>
            <Route path='article' element={<Article />}></Route>
            <Route path='ocpp_location' element={<OcppLocation />}></Route>
            <Route path='meter_value' element={<MeterValue />}></Route>
            <Route path='cdr' element={<Cdr />}></Route>
            <Route path='Session' element={<Session />}></Route>
            <Route path='authorize' element={<Authorize />}></Route>
            <Route path='status_ocpp' element={<StatusOcpp />}></Route>
            <Route path='alert_resa' element={<AlertResa />}></Route>
            <Route path='report' element={<Report />}></Route>
            <Route path='report_result' element={<ReportResult />}></Route>
            

          </Route>
          <Route path='/login' element={<Login />}></Route>


        </Routes>
      </div>


    </BrowserRouter>







  );
}

export default App;
