import { makeAutoObservable } from "mobx"
import { http } from "../utils"

class UserStore{
    userinfo={}
    constructor(){
        makeAutoObservable(this)
    };
    GetUserInfo=async ()=>{

        const res=await http.get("https://api1.clem.mobi/api/v2/users/0?withCommunities=yes");
        this.userinfo=res.data.data;
      //  console.log(this.userinfo.data.name);

    }

}
export default UserStore;