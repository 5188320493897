import { Navigate } from "react-router-dom";
import { getToken } from "../utils"


function AuthComponent({children}){

    const isToken=getToken();
    if(isToken){
        return <>{children}</>
    }else
    {

        return <Navigate to="/login" replace></Navigate>
    }

}

export {AuthComponent};