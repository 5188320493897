import React, { useState,useEffect } from 'react';
import { Descriptions, Card,Table } from 'antd';
import './index.scss';
import { http } from '../../utils'
import { useSearchParams } from 'react-router-dom';




const Article = () => {

    const [params]=useSearchParams();
    
    const id=params.get('id');

    const [data, setData] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [cdrs, setCdrs] = useState([]);

  
    useEffect(
        ()=>{
            const LoadDetail=async()=>
            {
                const stationdetail= await http.get(`http://172.16.3.3/api-ocpp-admin/public/index.php/api/ocpp/station_details?location_id=${id}`);
                setData(stationdetail.data.data);
  //              console.log(stationdetail.data.data);
            }

            const GetSessions=async()=>
            {
                const stationdetail= await http.get(`http://172.16.3.3/api-ocpp-admin/public/index.php/api/ocpp/sessions?location_id=${id}`);
                setSessions(stationdetail.data.data);
  //              console.log(stationdetail.data.data);
            }

            const GetCdrs=async()=>
            {
                const stationdetail= await http.get(`http://172.16.3.3/api-ocpp-admin/public/index.php/api/ocpp/cdrs?location_id=${id}`);
                setCdrs(stationdetail.data.data);
  //              console.log(stationdetail.data.data);
            }



            if(id)
            {
                LoadDetail();
                GetSessions();
                GetCdrs();
               
            }
        
        },[id]
    );
    const session_columns = [

        {
            title: 'Authenticate',
            dataIndex: 'media_id',
            key: 'media_id'
        },
        {
            title: 'start',
            dataIndex: 'start',
            key: 'start'
        },
        {
            title: 'end',
            dataIndex: 'end',
            key: 'end'
        },


    ];

    const cdr_columns = [

        {
            title: 'Authenticate',
            dataIndex: 'media_id',
            key: 'media_id'
        },
        {
            title: 'start',
            dataIndex: 'start',
            key: 'start'
        },
        {
            title: 'end',
            dataIndex: 'end',
            key: 'end'
        },
        {
            title: 'total_volume',
            dataIndex: 'total_volume',
            key: 'total_volume'
        },


    ];


    return (
        <div>
            <Card>
            <Descriptions title="Station details">
            <Descriptions.Item label="name">{data.location_name}</Descriptions.Item>
            <Descriptions.Item label="Id Ocpp">{data.id_ocpp}</Descriptions.Item>
            <Descriptions.Item label="Last Session">{data.session}</Descriptions.Item>
            <Descriptions.Item label="isOnline">{(data.isOnline ? 'Online' : 'Offline')}</Descriptions.Item>
            <Descriptions.Item label="session_start">{data.session_start}</Descriptions.Item>
            <Descriptions.Item label="session_stop">{data.session_stop}</Descriptions.Item>
            <Descriptions.Item label="type">{data.type}</Descriptions.Item>
            <Descriptions.Item label="last recevie">{data.updated_at}</Descriptions.Item>
            </Descriptions>
            </Card>
            <Card title={`Session ：`}>
                <Table
                    rowKey="id"
                    dataSource={sessions}
                    columns={session_columns}
                />
            </Card>

            <Card title={`Cdr ：`}>
                <Table
                    rowKey="id"
                    dataSource={cdrs}
                    columns={cdr_columns}
                />
            </Card>

        </div>

    );

}


export default Article;