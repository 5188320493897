import { Link, useNavigate } from 'react-router-dom';
import { Card, Breadcrumb, Form, Button, Input, Table, Select } from 'antd';
import './index.scss';
import { useState, useEffect } from 'react';
import { http } from '../../utils';

const AlertResa = () => {
    const [stationlist, setList] = useState({
        list: [],
        count: 0
    });
    const [params, setParams] = useState({});

    useEffect(() => {
        const LoadList = async () => {
            try {
                const res = await http.get('https://api-supervision.clem.mobi/api/stations/alert/last_resa', { params });
                const { data } = res.data;
                setList({
                    list: data,
                    count: data.length
                });
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };
        LoadList();
    }, [params]);

    const onFinish = (values) => {
        const { status, name, type } = values;
        const newParams = {};
        newParams.status = status;
        newParams.type = type;
        if (name) newParams.name = name;
        setParams({ ...params, ...newParams });
    };

    const navigate = useNavigate();

    const getCurrentTime = () => new Date().getTime();

    const rowClassName = (record) => {
        const endDate = new Date(record.last_reservation_blocking.enddate).getTime();
        return endDate > getCurrentTime() ? 'green-row' : 'red-row';
    };

    const columns = [
        {
            title: 'Nom de la station',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Date de la dernière réservation',
            dataIndex: 'last_reservation',
            key: 'enddate',
            render: (last_reservation) => new Date(last_reservation.enddate).toLocaleString(),
        },
        {
            title: 'Date de la première réservation',
            dataIndex: 'first_reservation',
            key: 'enddate',
            render: (first_reservation) => new Date(first_reservation.enddate).toLocaleString(),
        },
        {
            title: 'Date de la dernière réservation de blocage',
            dataIndex: 'last_reservation_blocking',
            key: 'enddate',
            render: (last_reservation_blocking) => new Date(last_reservation_blocking.enddate).toLocaleString(),
        },
        {
            title: 'Nombre de jours sans réservations',
            dataIndex: 'day_last_reservation',
            key: 'day_last_reservation',
            sorter: (a, b) => a.day_last_reservation - b.day_last_reservation,
            defaultSortOrder: 'ascend'
        },
    ];

    return (
        <div>
            <Card
                title={
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item>
                            <Link to="/home">Accueil</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>stations</Breadcrumb.Item>
                    </Breadcrumb>
                }
                style={{ marginBottom: 20 }}
            >
                <Form
                    style={{ maxWidth: 600 }}
                    onFinish={onFinish}
                >
                    <Form.Item label="name" name="name">
                        <Input placeholder="Nom de la station" />
                    </Form.Item>
                    <Form.Item label="type" name="type">
                        <Select placeholder="Type de station">
                             <Select.Option value="">Tous</Select.Option>
                            <Select.Option value="1">Autopartage</Select.Option>
                            <Select.Option value="0">Recharge</Select.Option>
                            <Select.Option value="7">Recharge immédiatement</Select.Option>
                            <Select.Option value="10">Vélo</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ marginLeft: 80 }}>
                            submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Card title={`Liste des emplacements：`}>
                <Table
                    rowKey="id"
                    dataSource={stationlist.list}
                    columns={columns}
                    rowClassName={rowClassName}
                    pagination={{
                        pageSize: 100,
                        showSizeChanger: false
                    }}
                    onRow={(record) => {
                        const endDate = new Date(record.last_reservation_blocking.enddate).getTime();
                        const className = endDate > getCurrentTime() ? 'green-row' : 'red-row';
                        return {
                            className,
                        };
                    }}
                />
            </Card>
        </div>
    );
};

export default AlertResa;
