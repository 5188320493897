import React, { useState, useEffect } from 'react';
import { Descriptions, Card, Form, Select, Button, DatePicker, Space, Table, Row, Col } from 'antd';
import './index.scss';
import { http } from '../../utils'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';


const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const Home = () => {



    const [data, setData] = useState({
        data:[],
        total:{
            total_energy:0
        }
    });




    useEffect(
        () => {
            const GetHome = async () => {
                const res = await http.get('https://api-supervision.clem.mobi/api/ocpp/home',{
                    timeout: 10000,
                  });
                const { data,total } = res.data;
                setData({data,total});
              

            }
            GetHome();

        }, []
    )


    const currentDate = new Date();

    // 使用getFullYear()方法获取当前年份
    const currentYear = currentDate.getFullYear();


    return (
        <div>
            <Row gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
            }}>

                <Col span={12}  >   <Card  style={{ width: 400 }}>
                    Welcome
                </Card> </Col>


                <Col span={12}>   <Card title="Total energy" bordered={false} style={{ width: 400 }}>
                    <p><b>Année {currentYear}</b></p>
                    <p><b className='blue-text'>{data.total.total_energy}</b></p>

                </Card> </Col>

            </Row>
            <Row gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
            }}>

            <Card title={`Total Energy ：`}>
                <div>
                    <h2>KWH</h2>
                    <BarChart width={600} height={400} data={data.data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="volume" fill="#8884d8" />
                    </BarChart>
                </div>


            </Card>
</Row>
        </div>

    );

}


export default Home;