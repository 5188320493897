import React, { useState, useEffect } from 'react';
import { Descriptions, Card,Form,Input,Select,Button,DatePicker,Space,Table } from 'antd';
import './index.scss';
import { http } from '../../utils'
import { useSearchParams } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';


const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const Authorize = () => {

    const [params] = useSearchParams();
    const id = params.get('id');


    const [tableData, submitTable] = useState(

        )

    const [stationlist, setList] = useState({
        list: [],
        count: 0
    });
    const [data, setData] = useState([]);
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);

    const onFinish = async (values) => {

        const newParams = {};
        newParams.station_id =values.location_id;
        let url=`http://172.16.3.3/api-ocpp-admin/public/index.php/api/ocpp/authorize?location_id=${ newParams.station_id}`;
        if (values.date) {
            newParams.startdate = values.date[0].format('YYYY-MM-DD')
            newParams.enddate = values.date[1].format('YYYY-MM-DD')
            url+=`&startdate=${newParams.startdate}&enddate=${newParams.enddate}&limit=-1`;
          }
    
    
        const stationdetail = await http.get(url);
        setData(stationdetail.data.data);
   
    };

    useEffect(
        () => {
            const LoadDetail = async () => {
                const stationdetail = await http.get(`http://172.16.3.3/api-ocpp-admin/public/index.php/api/ocpp/meter_value?station_id=${id}`);
                setData(stationdetail.data.data);
                setMinValue(stationdetail.data.data.min_value);
                setMaxValue(stationdetail.data.data.max_value);
            }

            const GetStations = async () => {
                const res = await http.get('http://172.16.3.3/api-ocpp-admin/public/index.php/api/ocpp/status');
                const { data } = res.data;
                setList({
                    list: data,
                    count: data.length
                })

            }
            GetStations();
            if (id) {
                LoadDetail();

            }

        }, [id]
    )

    const cdr_columns = [

        {
            title: 'idTag',
            dataIndex: 'idTag',
            key: 'idTag'
        },
        {
            title: 'datetime',
            dataIndex: 'datetime',
            key: 'datetime'
        },
        {
            title: 'id_ocpp',
            dataIndex: 'id_ocpp',
            key: 'id_ocpp'
        },
        {
            title: 'status',
            dataIndex: 'status',
            key: 'status'
        },


    ];

    const filterOption=(input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0


    return (
        <div>


            <Card>
                <Descriptions title="Authorize">

                <Space direction="vertical" size={12}>
                    <Form
                        style={{ maxWidth: 600 }}
                        onFinish={onFinish}
                        initialValues={{ status: -1 }}>

             


                        <Form.Item label="location name" name="location_id">
                            <Select
                                placeholder="location_name"
                                defaultValue=""
                                style={{ width: 300 }}
                                showSearch
                                filterOption={filterOption}
                            >
                                {stationlist.list.map((ss) => (
                                    <Select.Option key={ss.location_id} value={ss.location_id}>
                                        {ss.location_name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item label="date" name="date">
                        <RangePicker  format={dateFormat}></RangePicker>
                    </Form.Item>


                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ marginLeft: 80 }}>
                                submit
                            </Button>
                        </Form.Item>
                    </Form>
                    </Space>




                </Descriptions>

                <Card title={`Cdr ：`}>
                <Table
                    rowKey="id"
                    dataSource={data}
                    columns={cdr_columns}
                />
            </Card>
     
            </Card>

        </div>

    );

}


export default Authorize;