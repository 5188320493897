import React, { useState, useEffect } from 'react';
import {Card, Breadcrumb, Form, Button, Radio, DatePicker, Select, Table, Input, Space ,Descriptions } from 'antd';
import './index.scss';
import { http } from '../../utils'
import { useSearchParams } from 'react-router-dom';




const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
const StatusOcpp = () => {

    const [params] = useSearchParams();
    const id = params.get('id');
    const [dynamicOptions, setDynamicOptions] = useState([]); // 动态选项
    const [selectedFixedOption, setSelectedFixedOption] = useState(null);
    const [selectedDynamicOption, setSelectedDynamicOption] = useState(null);




    const [data, setData] = useState([]);

    const onFinish = async (values) => {
        const newParams = {};
        newParams.station_id =values.location_id;
        let url=`http://172.16.3.3/api-ocpp-admin/public/index.php/api/ocpp/StatusNotification?location_id=${ newParams.station_id}&type=${values.type}`;
        if (values.date) {
            newParams.startdate = values.date[0].format('YYYY-MM-DD');
            newParams.enddate = values.date[1].format('YYYY-MM-DD');
            url+=`&startdate=${newParams.startdate}&enddate=${newParams.enddate}&limit=-1`;
          }
    
        const stationdetail = await http.get(url);
        setData(stationdetail.data.data);
   
    };

    const handleSelectChange=async(value)=>{
        const res = await http.get('http://172.16.3.3/api-ocpp-admin/public/index.php/api/ocpp/status'+'?type='+value);
        const { data } = res.data;
        setDynamicOptions(data);
        setSelectedDynamicOption(null);
    }
    const handleDynamicOptionChange = (value) => {
        setSelectedDynamicOption(value);
      };

    useEffect(
        () => {
          
            const GetStations = async () => {
                const res = await http.get('http://172.16.3.3/api-ocpp-admin/public/index.php/api/ocpp/status');
                const { data } = res.data;
                setDynamicOptions(data);

            }
            GetStations();

        }, [id]
    )

    const cdr_columns = [

        {
            title: 'status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'errorCode',
            dataIndex: 'errorCode',
            key: 'errorCode'
        },
        {
            title: 'datetime',
            dataIndex: 'datetime',
            key: 'datetime'
        },
        {
            title: 'id_ocpp',
            dataIndex: 'id_ocpp',
            key: 'id_ocpp'
        }
      

    ];

    const filterOption=(input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0


    return (
        <div>


            <Card>
                <Descriptions title="StatusNotification">

                <Space direction="vertical" size={12}>
                    <Form
                        style={{ maxWidth: 600 }}
                        onFinish={onFinish}
                        initialValues={{ status: -1 }}>
                     <Form.Item label="type" name="type">
                        <Select
                        placeholder="type"
                        defaultValue="false"
                        style={{ width: 120 }}
                        onChange={handleSelectChange}
                            >
                            <Option value="false">All</Option>
                                <Option value="ocpp16">Ocpp16</Option>
                                <Option value="mopboxv5">Mopboxv5</Option>
                                <Option value="spoony">Spoony</Option>
                                <Option value="ccds">CCDS</Option>
                            </Select>
                        </Form.Item>
             


                        <Form.Item label="location name" name="location_id">
                            <Select
                                placeholder="location_name"
                                defaultValue=""
                                style={{ width: 300 }}
                                showSearch
                            
                                filterOption={filterOption}
                            >
                                {dynamicOptions.map((ss) => (
                                    <Select.Option key={ss.location_id} value={ss.location_id}>
                                        {ss.location_name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>


             

                        <Form.Item label="date" name="date">
                        <RangePicker  format={dateFormat}></RangePicker>
                    </Form.Item>


                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ marginLeft: 80 }}>
                                submit
                            </Button>
                        </Form.Item>
                    </Form>
                    </Space>




                </Descriptions>

                <Card title={`Cdr ：`}>
                <Table
                    rowKey="id"
                    dataSource={data}
                    columns={cdr_columns}
                />
            </Card>
     
            </Card>

        </div>

    );

}


export default StatusOcpp;