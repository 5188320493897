import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {Card, Table,List, Spin } from 'antd';
import { http } from '../../utils';

function ReportResult() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [sortedInfo, setSortedInfo] = useState({
    columnKey: 'diff_KWH',
    order: 'descend',
  });

  const [totalConsumption, setTotalConsumption] = useState(0);
  const [totalDiffKWH, setTotalDiffKWH] = useState(0);
  const [totalKwhNoResa, setTotalKwhNoResa] = useState(0);
  const [totalReservationKWH, setTotalReservationKWH] = useState(0);


  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const ids = query.getAll('location_ids[]');
    if (ids.length > 0) {
      http.get(`https://api-supervision.clem.mobi/api/borne/report?${query.toString()}`)
        .then(response => {
          setData(response.data.data);
          setLoading(false);

          const filteredData = response.data.data.filter(item => item.diff_KWH >0 );
        //setData(filteredData);

        // 计算Consumption总和
        const total = filteredData.reduce((acc, curr) => acc + curr.Consumption, 0);
        setTotalConsumption(total);

        // 计算diff_KWH总和
        const totalDiff = filteredData.reduce((acc, curr) => acc + curr.diff_KWH, 0);
        setTotalDiffKWH(totalDiff);

        // 计算count_reservation_kwh总和
        const totalReservation = filteredData.reduce((acc, curr) => acc + curr.count_reservation_kwh, 0);
        setTotalReservationKWH(totalReservation);

                // 计算diff_KWH总和
                const totalKwhNoResa = filteredData.reduce((acc, curr) => acc + curr.kwh_no_resa, 0);
                setTotalKwhNoResa(totalKwhNoResa);


        })
        .catch(error => {
          console.error('Error fetching report data:', error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [location.search]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'Start Value',
      dataIndex: 'start_value',
      key: 'start_value',
    },
    {
      title: 'Start Date',
      dataIndex: 'startdate',
      key: 'startdate',
    },
    {
      title: 'End Value',
      dataIndex: 'end_value',
      key: 'end_value',
    },
    {
      title: 'End Date',
      dataIndex: 'enddate',
      key: 'enddate',
    },
    {
      title: 'Consumption',
      dataIndex: 'Consumption',
      key: 'Consumption',
    },
    {
      title: 'Reservation kWh',
      dataIndex: 'count_reservation_kwh',
      key: 'count_reservation_kwh',
    },
    {
        title: 'Différence kWh',
        dataIndex: 'diff_KWH',
        key: 'diff_KWH',
        sorter: (a, b) => a.diff_KWH - b.diff_KWH,
        sortOrder: sortedInfo.columnKey === 'diff_KWH' && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: 'kWh NO Reservation',
        dataIndex: 'kwh_no_resa',
        key: 'kwh_no_resa',
      },
    {
        title: 'Count Reservation',
        dataIndex: 'count_reservation',
        key: 'count_reservation',
      },
    {
      title: 'Reservation Cancel',
      dataIndex: 'count_reservation_cancel',
      key: 'count_reservation_cancel',
    },
  ];
  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setSortedInfo(sorter);
  };


  return (
    <div>
  <Card>
        <div><b>Total Consumption:</b> {totalConsumption}</div>
     
        <div><b>Total Reservation_KWH:</b> {totalReservationKWH}</div>
        <div><b>Total Diff_KWH:</b> {totalDiffKWH}</div>
        <div><b>Total Recu KWH sans Reservation:</b> {totalKwhNoResa}</div>
        </Card>

    {loading ? (
      <Spin size="large" />
    ) : (
      <Table dataSource={data} columns={columns} onChange={handleChange}  rowKey="id" />
    )}
  </div>
  );
}

export default ReportResult;
