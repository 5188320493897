import React, { useState, useEffect } from 'react';
import { List, Checkbox, Spin, Button } from 'antd';
import axios from 'axios';
import { http } from '../../utils'
import { Link, useNavigate } from 'react-router-dom'


function Report() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checkedItems, setCheckedItems] = useState(new Set());
    const [isAllChecked, setIsAllChecked] = useState(false); // 全选状态
    const history = useNavigate();  // 使用 useHistory 钩子进行导航

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await http.get('https://api-supervision.clem.mobi/api/borne/report_locations');
      if (response.data.success && Array.isArray(response.data.data)) {
        setData(response.data.data);
        setCheckedItems(new Set());
        setIsAllChecked(false);
      } else {
        setData([]);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setData([]);
      setLoading(false);
    }
  };

  const handleCheck = (id) => {
    const newChecked = new Set(checkedItems);
    if (newChecked.has(id)) {
      newChecked.delete(id);
    } else {
      newChecked.add(id);
    }
    setCheckedItems(newChecked);
    setIsAllChecked(newChecked.size === data.length);
  };

  const handleToggleAll = () => {
    if (isAllChecked) {
      setCheckedItems(new Set());
      setIsAllChecked(false);
    } else {
      const newChecked = new Set(data.map(item => item.id));
      setCheckedItems(newChecked);
      setIsAllChecked(true);
    }
  };
  const handleConfirm = () => {
    const idsQuery = Array.from(checkedItems).map(id => `location_ids[]=${id}`).join('&');
    history(`/report_result?${idsQuery}`);  // 导航到报告页面并传递选中的 ID
  };

  return (
    <div style={{ padding: '20px' }}>
     <Button type="primary" onClick={handleConfirm} style={{ marginBottom: 20 }}>Confirm Selection</Button>
      {loading ? (
        <Spin size="large" />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginBottom: 10, display: 'flex', alignItems: 'center' }}>
            <Checkbox
              onChange={handleToggleAll}
              checked={isAllChecked}
              indeterminate={checkedItems.size > 0 && checkedItems.size < data.length}
              style={{ marginRight: 8 }}
            /> Select All
            <div style={{ marginLeft: 'auto', marginRight: 8, width: '100%', textAlign: 'center' }}>Name</div>
          </div>
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={item => (
              <List.Item style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  onChange={() => handleCheck(item.id)}
                  checked={checkedItems.has(item.id)}
                  style={{ marginRight: 8 }}
                />
                <div style={{ flex: 1, textAlign: 'center' }}>{item.name}</div>  {/* 这里设置文本居中 */}
              </List.Item>
            )}
          />
        </div>
      )}
    </div>
  );

}

export default Report;
